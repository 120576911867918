import React from 'react'
import { BsLinkedin, BsGithub } from 'react-icons/bs'
import { HiOutlineMail } from 'react-icons/hi'

const SocialMedia = () => (
  <div className="app__social">
    <a href='https://github.com/acderusha' target="_blank" rel="noreferrer">
      <BsGithub />
    </a>
    <a href='https://www.linkedin.com/in/andrew-derusha/' target="_blank" rel="noreferrer">
      <BsLinkedin />
    </a>
    <a href='#contact'>
      <HiOutlineMail />
    </a>
  </div>
)

export default SocialMedia;