import React, { useState } from 'react'

import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper'
import './Contact.scss'

import emailjs from '@emailjs/browser';

const Contact = () => {
  const [formData, setFormData] = useState({ username: '', email: '', message: ''})
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const { username, email, message } = formData
  const errorMessage = "Oops! Looks like an error occured. Please make sure you filled out all the fields and try again!"
  const validEmailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
  const notEmptyPattern =  /^.+$/

  const updateInvalidFields = (name, isValid) => {
    if(!isValid && !invalidInputs.includes(name)) {
      setInvalidInputs(curInval => curInval.concat(name))
    } else if (isValid) {
      setInvalidInputs(curInval => curInval.filter((invalidField) => invalidField !== name))
    }
  }

  const testInputValue = (value, pattern) => {
    return pattern.test(value)
  }

  const isValidInput = (name, value) => {
    switch (name) {
      case 'username':
      case 'message':
        return testInputValue(value, notEmptyPattern)
      case 'email':
        return testInputValue(value, validEmailPattern)
      default:
        return false
    }
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target
    
    updateInvalidFields(name, true)
    setFormData(curFormData => {
      return {...curFormData, [name]: value}
    })
  }

  const handleBlurInput = (e) => {
    const { name, value } = e.target
    updateInvalidFields(name, isValidInput(name, value))
  }

  const handleSendError = () => {
    setLoading(false)
    setIsError(true);
  }

  const handleSendSuccess = () => {
    setLoading(false)
    setIsFormSubmitted(true)
  }

  const checkValidSubmission = () => {
    return Object.entries(formData)
      .map(([key, value]) => {
        const isFieldValid = isValidInput(key, value)
        updateInvalidFields(key, isFieldValid)
        return isFieldValid
      })
      .every(isValidEntry => isValidEntry)
  }

  const handleSubmit = () => {
    setLoading(true)
    setIsError(false)

    if(!checkValidSubmission()) {
      handleSendError()
      return
    }

    try {
      emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, 
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
        formData, 
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(() => {
        handleSendSuccess()
      }).catch(() => {
        handleSendError()
      })
    } catch {
      handleSendError()
    }
  }

  return (
    <>
      <h2 className="head-text">Let's <span>Chat</span> More!</h2>

      <div className="app__contact-cards">
        <div className="app__contact-card ">
          <img src={images.email_circle} alt="email" />
          <a href="mailto:acderusha@verizon.net" className="p-text">acderusha@verizon.net</a>
        </div>
        <div className="app__contact-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+1 (781) 439-3184" className="p-text">+1 (781) 439-3184</a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <form className="app__contact-form app__flex">
          <input 
            className={`p-text ${invalidInputs.includes("username") ? "invalid" : "valid"}`}
            type="text" 
            placeholder="Name" 
            name="username" 
            value={username} 
            onChange={handleChangeInput} 
            onBlur={handleBlurInput} 
          />
          <input 
            className={`p-text ${invalidInputs.includes("email") ? "invalid" : "valid"}`} 
            type="email" 
            placeholder="Email" 
            name="email" 
            value={email} 
            onChange={handleChangeInput} 
            onBlur={handleBlurInput} 
          />
          <textarea
            className={`p-text ${invalidInputs.includes("message") ? "invalid" : "valid"}`}
            placeholder="Message"
            value={message}
            name="message"
            onChange={handleChangeInput}
            onBlur={handleBlurInput}
          />
          {isError && <p className="p-text-error">{errorMessage}</p>}
          <button type="button" className="p-text" onClick={handleSubmit} disabled={loading}>
            {!loading ? 'Send Message' : 'Sending...'}
          </button>
        </form>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>
  )
}

export default AppWrap(
  MotionWrap(Contact, 'app__contact'),
  'contact',
  'app__primarybg',
)