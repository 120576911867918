import React, { useState, useEffect } from 'react'
import { HiMenuAlt4, HiX } from 'react-icons/hi'

import { images, pdfs } from '../../constants'
import './Navbar.scss'

const Navbar = () => {
  const menuItems = ['home', 'about', 'work', 'skills', 'contact']

  const [toggleMobileNav, setToggle] = useState(false)
  const mobileNavStype = toggleMobileNav ? 'app__navbar-flyout' : 'app__navbar-flyout-hide'

  const [collapse, setCollapse] = useState(false)
  const navbarCollapseStyle = !collapse ? 'app__navbar' : 'app__navbar-collapse'
  
  const [lastScrollY, setLastScrollY] = useState(0);
  const collaspeNavbar = () => {
    if (typeof window !== 'undefined') { 
      if (window.scrollY > 0 && window.scrollY > lastScrollY && !toggleMobileNav) {
        setCollapse(true); 
      } else {
        setCollapse(false);  
      }

      setLastScrollY(window.scrollY); 
    }
  }
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', collaspeNavbar);

      return () => {
        window.removeEventListener('scroll', collaspeNavbar);
      };
    }
  // eslint-disable-next-line
  }, [lastScrollY])

  return (
    <nav className={navbarCollapseStyle}>
      <a className="app__navbar-logo"  href="#home">
        <img src={images.personal_logo} alt="logo" />
      </a>
      <ul className="app__navbar-links">
        {menuItems.map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
        <li className="app__flex p-text" key="link-resume">
          <div />
          <a href={pdfs.resume} target="_blank" rel="noreferrer">resume</a>
        </li>
      </ul>

      <div className='app__navbar-menu'>
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        <div className={mobileNavStype}>
          <div className="app__navbar-flyout-close">
            <HiX onClick={() => setToggle(false)} />
          </div>
          <ul>
            {menuItems.map((item) => (
              <li key={item}>
                <a href={`#${item}`} onClick={() => setToggle(false)}>
                  {item}
                </a>
              </li>
            ))}
            <li className="app__flex p-text" key="link-resume">
              <a href={pdfs.resume} target="_blank" rel="noreferrer">resume</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar